.right {
    margin: 1rem;
    text-align: right;
}
.medium-margin{
    margin: 1.5rem;
}

.botones{
    margin: 10px;
    float: right;
   
}