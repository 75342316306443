@import '~react-perfect-scrollbar/dist/css/styles.min.css';
@import './prismjs';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

:not(pre) > code {
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  background-color: #eeeeee;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
}
.horiz-menu > div {
  display: inline-block;
}
.absolute-container{
  margin: 1.5rem
}


.medium-space{
  margin:.5rem
}
.right {
  text-align: right;

}
.total-right {
  float: right;
  width: 100%;
  margin-bottom: 0.5rem

}
.table-size {
  width: 100%;
}
.margin-bottom {
  margin-bottom: 1.5rem;
}
.margin-top {
  margin-top: 1.5rem;
}
.red-text {
  color: red;
}
.total-left {
  float: left;
  width: 100%;
  margin-bottom: 0.5rem

}
.left {
  width: 100%;
  text-align: left;
}
.right-inline {
  text-align: right;
}
.vertical-center {
  align-items: center;
}

/**------------------------Button loading*/
.wrapper {
  margin: 1.5rem;
  position: relative;
}

.wrapper-not-margin {
  position: relative;
}

.buttonProgress {
  color: green;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 0;
  margin-top: -12;
  margin-left: -12;
  
}